import { createAutoAnimatePlugin, createFloatingLabelsPlugin } from "@formkit/addons";
import "@formkit/addons/css/floatingLabels";
// import { generateClasses } from "@formkit/themes";
// import formkitTheme from "./formkit-theme.js";

export default {
  icons: {},
  // config: {
  //   classes: generateClasses(formkitTheme),
  // },
  plugins: [
    createFloatingLabelsPlugin(),
    createAutoAnimatePlugin(
      {
        duration: 250,
        easing: "ease-in-out",
        delay: 0,
      },
      {
        global: ["outer", "inner"],
        form: ["form"],
        repeater: ["items"],
      },
    ),
  ],
};
