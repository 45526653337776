import { defineNuxtPlugin } from '#app/nuxt'
import { LazyLBlog, LazyLButton, LazyLCard, LazyLFeat, LazyLFeature, LazyLFooter, LazyLH1, LazyLH2, LazyLH3, LazyLH4, LazyLHeader, LazyLLogo, LazyLLogoScroll, LazyLNavItem, LazyLPageBreadcrumb, LazyLPrice, LazyLSection, LazyLSectionPricing, LazyLTestimonial, LazyLWhatsIncluded, LazyArticlesMoleculeCaseStudies, LazyArticlesMoleculeContentSection, LazyArticlesMoleculeSoftwareCategory, LazyArticlesMoleculeSoftwareLists, LazyArticlesMoleculeStatusBanner, LazySignupAcceptTerms, LazySignupButton, LazySignupCalendar, LazySignupContainer, LazySignupDaysOfTheWeek, LazySignupForm, LazySignupInput, LazySignupLoading, LazySignupMarketingOptIn, LazySignupMonthSelector, LazySignupSuccessModal, LazySignupViewConsultationCalendar, LazySignupViewSignup, LazyTemplatesDashboard, LazyTemplatesDocuments, LazyTemplatesRegistration, LazyCompetitor, LazyContent, LazyLanding, LazyOrg, LazyProfile, LazyUser, LazySvgoHippo, LazySvgoHowItWorksImage1, LazySvgoHowItWorksImage2, LazySvgoHowItWorksImage3, LazySvgoHowItWorksImage4, LazySvgoLoading, LazySvgoAccountable, LazySvgoCheckmark, LazySvgoCompliancyGroup, LazySvgoCreditcard, LazySvgoCursor, LazySvgoDownload, LazySvgoEclipse, LazySvgoEclipse2, LazySvgoEnvelope, LazySvgoFacebook, LazySvgoGraduationcap, LazySvgoHamburger, LazySvgoHipaaTraining, LazySvgoHippoLogo, LazySvgoInstagram, LazySvgoInviteTeam, LazySvgoLinkedin, LazySvgoMoon, LazySvgoPlay, LazySvgoRegister, LazySvgoSun, LazySvgoTwitter } from '#components'
const lazyGlobalComponents = [
  ["LBlog", LazyLBlog],
["LButton", LazyLButton],
["LCard", LazyLCard],
["LFeat", LazyLFeat],
["LFeature", LazyLFeature],
["LFooter", LazyLFooter],
["LH1", LazyLH1],
["LH2", LazyLH2],
["LH3", LazyLH3],
["LH4", LazyLH4],
["LHeader", LazyLHeader],
["LLogo", LazyLLogo],
["LLogoScroll", LazyLLogoScroll],
["LNavItem", LazyLNavItem],
["LPageBreadcrumb", LazyLPageBreadcrumb],
["LPrice", LazyLPrice],
["LSection", LazyLSection],
["LSectionPricing", LazyLSectionPricing],
["LTestimonial", LazyLTestimonial],
["LWhatsIncluded", LazyLWhatsIncluded],
["ArticlesMoleculeCaseStudies", LazyArticlesMoleculeCaseStudies],
["ArticlesMoleculeContentSection", LazyArticlesMoleculeContentSection],
["ArticlesMoleculeSoftwareCategory", LazyArticlesMoleculeSoftwareCategory],
["ArticlesMoleculeSoftwareLists", LazyArticlesMoleculeSoftwareLists],
["ArticlesMoleculeStatusBanner", LazyArticlesMoleculeStatusBanner],
["SignupAcceptTerms", LazySignupAcceptTerms],
["SignupButton", LazySignupButton],
["SignupCalendar", LazySignupCalendar],
["SignupContainer", LazySignupContainer],
["SignupDaysOfTheWeek", LazySignupDaysOfTheWeek],
["SignupForm", LazySignupForm],
["SignupInput", LazySignupInput],
["SignupLoading", LazySignupLoading],
["SignupMarketingOptIn", LazySignupMarketingOptIn],
["SignupMonthSelector", LazySignupMonthSelector],
["SignupSuccessModal", LazySignupSuccessModal],
["SignupViewConsultationCalendar", LazySignupViewConsultationCalendar],
["SignupViewSignup", LazySignupViewSignup],
["TemplatesDashboard", LazyTemplatesDashboard],
["TemplatesDocuments", LazyTemplatesDocuments],
["TemplatesRegistration", LazyTemplatesRegistration],
["Competitor", LazyCompetitor],
["Content", LazyContent],
["Landing", LazyLanding],
["Org", LazyOrg],
["Profile", LazyProfile],
["User", LazyUser],
["SvgoHippo", LazySvgoHippo],
["SvgoHowItWorksImage1", LazySvgoHowItWorksImage1],
["SvgoHowItWorksImage2", LazySvgoHowItWorksImage2],
["SvgoHowItWorksImage3", LazySvgoHowItWorksImage3],
["SvgoHowItWorksImage4", LazySvgoHowItWorksImage4],
["SvgoLoading", LazySvgoLoading],
["SvgoAccountable", LazySvgoAccountable],
["SvgoCheckmark", LazySvgoCheckmark],
["SvgoCompliancyGroup", LazySvgoCompliancyGroup],
["SvgoCreditcard", LazySvgoCreditcard],
["SvgoCursor", LazySvgoCursor],
["SvgoDownload", LazySvgoDownload],
["SvgoEclipse", LazySvgoEclipse],
["SvgoEclipse2", LazySvgoEclipse2],
["SvgoEnvelope", LazySvgoEnvelope],
["SvgoFacebook", LazySvgoFacebook],
["SvgoGraduationcap", LazySvgoGraduationcap],
["SvgoHamburger", LazySvgoHamburger],
["SvgoHipaaTraining", LazySvgoHipaaTraining],
["SvgoHippoLogo", LazySvgoHippoLogo],
["SvgoInstagram", LazySvgoInstagram],
["SvgoInviteTeam", LazySvgoInviteTeam],
["SvgoLinkedin", LazySvgoLinkedin],
["SvgoMoon", LazySvgoMoon],
["SvgoPlay", LazySvgoPlay],
["SvgoRegister", LazySvgoRegister],
["SvgoSun", LazySvgoSun],
["SvgoTwitter", LazySvgoTwitter],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
