import revive_payload_client_OCx2ypEaK8 from "/Users/nficano/code/teachmehipaa/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_qyhbpacdsgpyqa2f5cbnxplf7e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_HQJvlxndzD from "/Users/nficano/code/teachmehipaa/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_qyhbpacdsgpyqa2f5cbnxplf7e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_BiKoldMMxy from "/Users/nficano/code/teachmehipaa/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_qyhbpacdsgpyqa2f5cbnxplf7e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_ML3qiAGOlP from "/Users/nficano/code/teachmehipaa/web/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_terser@5.34.1__vue@3.5.12/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_q2TKZXNdh8 from "/Users/nficano/code/teachmehipaa/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_qyhbpacdsgpyqa2f5cbnxplf7e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_35xeiyShQu from "/Users/nficano/code/teachmehipaa/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_qyhbpacdsgpyqa2f5cbnxplf7e/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_EoNc0vFMHA from "/Users/nficano/code/teachmehipaa/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_qyhbpacdsgpyqa2f5cbnxplf7e/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_YsdygLdmK6 from "/Users/nficano/code/teachmehipaa/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_qyhbpacdsgpyqa2f5cbnxplf7e/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_MeZLXzMw0k from "/Users/nficano/code/teachmehipaa/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_qyhbpacdsgpyqa2f5cbnxplf7e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cross_origin_prefetch_client_uuWxbHKOVH from "/Users/nficano/code/teachmehipaa/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_qyhbpacdsgpyqa2f5cbnxplf7e/node_modules/nuxt/dist/app/plugins/cross-origin-prefetch.client.js";
import plugin_vue3_ow2Sa9ldBm from "/Users/nficano/code/teachmehipaa/web/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.12/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Users/nficano/code/teachmehipaa/web/.nuxt/components.plugin.mjs";
import prefetch_client_6Vr1XJ0ZSf from "/Users/nficano/code/teachmehipaa/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_qyhbpacdsgpyqa2f5cbnxplf7e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_YQd3C1qsWg from "/Users/nficano/code/teachmehipaa/web/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.0_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_terser@5.34.1__vue@3.5.12/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_jj3JZXfHje from "/Users/nficano/code/teachmehipaa/web/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_terser@5.34.1__vue@3.5.12/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_HDlUmdCYNt from "/Users/nficano/code/teachmehipaa/web/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_terser@5.34.1__vue@3.5.12/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_client_SAlze24dE1 from "/Users/nficano/code/teachmehipaa/web/node_modules/.pnpm/nuxt-gtag@3.0.1_magicast@0.3.5_rollup@4.24.0/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_Huh8JJeGZ0 from "/Users/nficano/code/teachmehipaa/web/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.24.0/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_BMhcpny9Gh from "/Users/nficano/code/teachmehipaa/web/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node_esuqzszy3o2744kuboizr7x5sm/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import motion_qcacArlSb8 from "/Users/nficano/code/teachmehipaa/web/node_modules/.pnpm/@vueuse+motion@2.2.6_magicast@0.3.5_rollup@4.24.0_vue@3.5.12/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import plugin_client_vDtoSunEbk from "/Users/nficano/code/teachmehipaa/web/node_modules/.pnpm/@nuxtjs+prismic@3.4.3_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_terser@5.34.1__vue@3.5.12/node_modules/@nuxtjs/prismic/dist/runtime/plugin.client.js";
import plugin_RxkgLGjMyr from "/Users/nficano/code/teachmehipaa/web/node_modules/.pnpm/@nuxtjs+prismic@3.4.3_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_terser@5.34.1__vue@3.5.12/node_modules/@nuxtjs/prismic/dist/runtime/plugin.js";
import formkitPlugin_pZqjah0RUG from "/Users/nficano/code/teachmehipaa/web/.nuxt/formkitPlugin.mjs";
import nuxt_plugin_EDVYz58coz from "/Users/nficano/code/teachmehipaa/web/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.24.0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_q1zG4Ovyzg from "/Users/nficano/code/teachmehipaa/web/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_vue@3._x54vwn27mrj5yix7hkytjsbesm/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import bus_client_VwprazHOAT from "/Users/nficano/code/teachmehipaa/web/plugins/bus.client.js";
import firebase_client_nS52P0yitD from "/Users/nficano/code/teachmehipaa/web/plugins/firebase.client.js";
import glightbox_client_6quUFNQCSf from "/Users/nficano/code/teachmehipaa/web/plugins/glightbox.client.js";
import gtag_client_R70iZRv6jK from "/Users/nficano/code/teachmehipaa/web/plugins/gtag.client.js";
import defaults_syNQ4sbbOy from "/Users/nficano/code/teachmehipaa/web/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.0_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_terser@5.34.1__vue@3.5.12/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_OCx2ypEaK8,
  unhead_HQJvlxndzD,
  router_BiKoldMMxy,
  _0_siteConfig_ML3qiAGOlP,
  payload_client_q2TKZXNdh8,
  navigation_repaint_client_35xeiyShQu,
  check_outdated_build_client_EoNc0vFMHA,
  view_transitions_client_YsdygLdmK6,
  chunk_reload_client_MeZLXzMw0k,
  cross_origin_prefetch_client_uuWxbHKOVH,
  plugin_vue3_ow2Sa9ldBm,
  components_plugin_KR1HBZs4kY,
  prefetch_client_6Vr1XJ0ZSf,
  titles_YQd3C1qsWg,
  siteConfig_jj3JZXfHje,
  inferSeoMetaPlugin_HDlUmdCYNt,
  plugin_client_SAlze24dE1,
  plugin_Huh8JJeGZ0,
  plugin_BMhcpny9Gh,
  motion_qcacArlSb8,
  plugin_client_vDtoSunEbk,
  plugin_RxkgLGjMyr,
  formkitPlugin_pZqjah0RUG,
  nuxt_plugin_EDVYz58coz,
  plugin_q1zG4Ovyzg,
  bus_client_VwprazHOAT,
  firebase_client_nS52P0yitD,
  glightbox_client_6quUFNQCSf,
  gtag_client_R70iZRv6jK,
  defaults_syNQ4sbbOy
]