import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  preserveAspectRatio: "xMinYMid meet",
  viewBox: "0 0 80 43"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { fill: "currentColor" }, [
      _createElementVNode("path", { d: "M54.088 8.866a1.706 1.706 0 1 1 0 3.413 1.706 1.706 0 0 1 0-3.413m18.843 5.674c0 .63.355 1.14.79 1.14.437 0 .792-.51.792-1.14 0-.63-.355-1.14-.791-1.14-.437 0-.79.51-.79 1.14Zm2.516 15c11.072-11.5.368-26.319-15.15-20.725-1.486-2.523-3.849-4.302-7.77-4.755-.517-5.863-7.225-5.608-7.134 2.286-10.63 8.54-29.666-.472-37.774 11.05-2.025 1.447-4.69 2.08-7.619 2.384 1.916.609 3.875.864 5.862.866-2.04 7.746-.178 16.75 2.803 23.328H17.4c0-5.45 4.42-9.87 9.87-9.87 5.451 0 9.87 4.42 9.87 9.87h7.634c5.653-29.15 17.503-.754 30.673-14.434Z" }),
      _createElementVNode("path", {
        d: "M24.416 43.974c.222-2.694.672-5.282 1.663-7.615a7.708 7.708 0 0 0-6.516 7.615h4.852Zm29.782 0c-.211-3.503-1.285-7.575-2.62-11.013-2.703 1.939-4.057 8.252-4.6 11.013h7.22Z",
        opacity: ".263"
      })
    ], -1)
  ])))
}
export default { render: render }