/* eslint-disable require-await */
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig();
  const clientConfig = {
    apiKey: config.public.apiKey,
    authDomain: config.public.authDomain,
    projectId: config.public.projectId,
    storageBucket: config.public.storageBucket,
    messagingSenderId: config.public.messagingSenderId,
    appId: config.public.appId,
    measurementId: config.public.measurementId,
  };
  const app = initializeApp(clientConfig, "client");
  const auth = getAuth(app);

  return {
    provide: {
      app,
      auth,
      db: getFirestore(app),
      storage: getStorage(app),
    },
  };
});
