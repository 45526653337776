import { default as _91uid_93kkltF74ZzwMeta } from "/Users/nficano/code/teachmehipaa/web/pages/compliant-software/[uid].vue?macro=true";
import { default as indexUvLNCImRFiMeta } from "/Users/nficano/code/teachmehipaa/web/pages/compliant-software/index.vue?macro=true";
import { default as indexluaM1n9xvtMeta } from "/Users/nficano/code/teachmehipaa/web/pages/index.vue?macro=true";
import { default as privacyaGkQJjPoj2Meta } from "/Users/nficano/code/teachmehipaa/web/pages/privacy.vue?macro=true";
import { default as signup9er2nUr5ylMeta } from "/Users/nficano/code/teachmehipaa/web/pages/signup.vue?macro=true";
import { default as termsJDfcx6Jt4zMeta } from "/Users/nficano/code/teachmehipaa/web/pages/terms.vue?macro=true";
import { default as component_45stubyVWQJcVijTMeta } from "/Users/nficano/code/teachmehipaa/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_qyhbpacdsgpyqa2f5cbnxplf7e/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubyVWQJcVijT } from "/Users/nficano/code/teachmehipaa/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_qyhbpacdsgpyqa2f5cbnxplf7e/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "compliant-software-uid",
    path: "/compliant-software/:uid()",
    component: () => import("/Users/nficano/code/teachmehipaa/web/pages/compliant-software/[uid].vue")
  },
  {
    name: "compliant-software",
    path: "/compliant-software",
    component: () => import("/Users/nficano/code/teachmehipaa/web/pages/compliant-software/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/nficano/code/teachmehipaa/web/pages/index.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/Users/nficano/code/teachmehipaa/web/pages/privacy.vue")
  },
  {
    name: "signup",
    path: "/signup",
    component: () => import("/Users/nficano/code/teachmehipaa/web/pages/signup.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/Users/nficano/code/teachmehipaa/web/pages/terms.vue")
  },
  {
    name: component_45stubyVWQJcVijTMeta?.name,
    path: "/login",
    component: component_45stubyVWQJcVijT
  }
]